<template>

  <form ref="form" @submit="submitForm" id="searchForm" class="formDesing">
    <div class="form-group row">
      <label htmlFor="club" class='col-sm-2 col-form-label'>Név / Name</label>
      <div class="col-sm-10">
        <input type="text"
          class="form-control"
          name="name"
          id="name"
          placeholder="Név / Name" />
      </div>
    </div>

    <div v-if="advanced">

      <div class="form-group row">
        <label htmlFor="bib" class='col-sm-2 col-form-label'>Rajtszám / Bib</label>
        <div class="col-sm-10">
          <input type="number"
            class="form-control"
            name="bib"
            id="bib"
            placeholder="Rajtszám / Bib" />
        </div>
      </div>


      <div class="form-group row">
        <label htmlFor="club" class='col-sm-2 col-form-label'>Egyesület / Sports club</label>
        <div class="col-sm-10">
          <input type="text"
            class="form-control"
            name="club"
            id="club"
            placeholder="Egyesület / Sports club" />
        </div>
      </div>

      <div class="form-group row">
        <label htmlFor="category" class='col-sm-2 col-form-label'>Kategória / Category</label>
        <div class="col-sm-10">
          <input type="text"
            class="form-control"
            name="category"
            id="category"
            placeholder="Kategória / Category" />
        </div>
      </div>

      <div class="form-group row">
        <label htmlFor="sex" class='col-sm-2 col-form-label'>Nem / Sex</label>
        <div class="col-sm-10">
          <select class="form-control" id="sex" name="sex">
            <option value="" defaultValue>Mindenki / All</option>
            <option value="N">Nő / Woman ♀</option>
            <option value="F">Férfi / Man ♂</option>
          </select>
        </div>
      </div>


      <div class="form-group row">
        <label htmlFor="minYob" class='col-sm-2 col-form-label'>Születési év / Birth year</label>
        <div class="col-sm-5">
          <input type="number"
            min="1900"
            max="2030"
            step="1"
            class="form-control"
            name="minYob"
            id="minYob"
            placeholder="Minimum születési év / Minimum birth year" />
        </div>
        <div class="col-sm-5">
          <input type="number"
            min="1900"
            max="2030"
            step="1"
            class="form-control"
            name="maxYob"
            id="maxYob"
            placeholder="Maximum születési év / Maximum birth year" />
        </div>
      </div>


      <div class="form-group row">
        <label htmlFor="country" class='col-sm-2 col-form-label'>Ország / Country</label>
        <div class="col-sm-10">
          <input type="text"
            class="form-control"
            name="country"
            id="country"
            placeholder="Ország / Country" />
        </div>
      </div>

      <div class="form-group row">
        <label htmlFor="club" class='col-sm-2 col-form-label'>Város / City</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" name="town" id="town" placeholder="Város / City" />
        </div>
      </div>


      <div class="form-group row">
        <label htmlFor="minPlace" class='col-sm-2 col-form-label'>Helyezés / Rank (min - max)</label>
        <div class="col-sm-5">
          <input type="number" class="form-control" name="minPlace" id="minPlace" placeholder="Minimum helyezés / Rank" />
        </div>
        <div class="col-sm-5">
          <input type="number" class="form-control" name="maxPlace" id="maxPlace" placeholder="Maximum helyezés / Rank" />
        </div>
      </div>


      <div class="form-group row">
        <label htmlFor="minNetTime" class='col-sm-2 col-form-label'>Nettó idő / Net time (min - max)</label>
        <div class="col-sm-5">
          <input type="text" class="form-control" name="minNetTime" id="minNetTime" placeholder="0:34" />
        </div>
        <div class="col-sm-5">
          <input type="text" class="form-control" name="maxNetTime" id="maxNetTime" placeholder="1:39" />
        </div>
      </div>

      
    </div>
    <button type="submit"
      id="search-button"
      class="btn btn-secondary advancedSearchButton">
      Keresés / Search
    </button>
    <button type="button" class="btn transitionButton btn-outline-light" @click="toggleAdvanced">Részletes kereső / Advanced Search</button>
    <button type="button"
      class="btn advancedSearchButton transitionButton btn-outline-light"
      @click="searchReset">
      Keresés alaphelyzetbe / Clear all
    </button>
  </form>

</template>



<script>

  import { mapActions } from 'vuex'

  export default {
    name: 'RaceForm',
    data: function() {
      return {
        advanced: false
      }
    },
    methods: {
      ...mapActions(['sendForm']),
      toggleAdvanced() {
        this.advanced = this.advanced ? false : true
      },
      submitForm(event) {
        event.preventDefault()
        this.sendData()
      },
      searchReset() {
        this.$refs.form.reset()
        this.sendData()
      },
      sendData() {
        let myForm = document.getElementById('searchForm')
        let formData = new FormData(myForm)
        this.sendForm({ form: formData, raceId: this.$route.params.raceId })
      }
    },
  }

</script>
<style scoped>

  .formDesing {
    background-image: -webkit-gradient(linear, right top, left top, from(#0adde5), to(#0387e9));
    background-image: linear-gradient(270deg, #0adde5, #0387e9);
    color: #fff;
    padding: 30px 30px 45px;
    text-align: left;
  }

  #search-button {
    background: #012d4e;
    color: #fff;
  }

  .form-group {
    margin-bottom: 5px;
  }

  button{
    margin-right: 5px;
  }

</style>
