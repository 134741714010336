import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { FontAwesomeIcon } from "@/modules/authentication/plugins/font-awesome";

const app = createApp(App);

app.use(router).use(store).component("font-awesome-icon", FontAwesomeIcon); //add the icons as a component to the application (specified in font-awesome.js)

app.mount("#app");
