<template>
  <nav class="navbar navbar-expand navbar-dark bg-primary" >
    <div class="navbar-nav">
      <li class="nav-item">
        <router-link to="/eventList" class="nav-link">
          EventList
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/virtualRace" class="nav-link">
          Virtual Race
        </router-link>
      </li>
      <li v-if="isCurrentUserAdmin" class="nav-item">
        <router-link to="/upload" class="nav-link">
          Upload Virtual Race
        </router-link>
      </li>
    </div>

    <div v-if="!currentUser" class="navbar-nav ms-auto">
      <li class="nav-item">
        <router-link to="/register" class="nav-link">
          <font-awesome-icon icon="user-plus" /> Sign Up
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/login" class="nav-link">
          <font-awesome-icon icon="sign-in-alt" /> Login
        </router-link>
      </li>
    </div>

    <div v-if="currentUser" class="navbar-nav ms-auto">
      <li class="nav-item">
        <router-link to="/profile" class="nav-link">
          <font-awesome-icon icon="user" />
          {{ currentUser.username }}
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click.prevent="logOut">
          <font-awesome-icon icon="sign-out-alt" /> LogOut
        </a>
      </li>
    </div>

  </nav>


  <router-view />

</template>


<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isCurrentUserAdmin() {
      if (this.$store.state.auth.user) {
        if (this.$store.state.auth.user.email === "admin@gmail.com") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
      
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    }
  }
};
</script>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
