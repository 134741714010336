<script>
export default {
  props: ["event", "index"],
  computed: {
    collapsed() {
      return this.index >= 0 ? true : false;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header" :id="event.id + event.eventStart">
      <h2 class="mb-0">
        <button class="btn btn-link" type="button" data-bs-toggle="collapse" :href="'#col' + index" aria-expanded="false" :aria-controls="'col' + index" >
          <span>{{ event.name }}</span>
        </button>
      </h2>
      <div class="event-date">
        <span class="raceDate">{{ event.eventStart }}. - {{ event.eventEnd }}.</span>
      </div>
    </div>
    <div :id="'col' + index" class="collapse" :class="{ show: !collapsed }" :aria-labelledby="event.id + event.eventStart" data-parent="#Events">
      <div class="card-body">
        <div class="list-group">
          <div :key="ra.id" v-for="ra in event.races">
            <a :href="'/race/' + ra.year + '/' + ra.id" class="list-group-item list-group-item-action">
                <span class="raceName">{{ ra.name }}</span>
                <br />
                <span class="raceDate">Rajt: {{ ra.date }} | Táv: {{ ra.distance }} km</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.btn-link {
  color: #2b364c !important;
  display: block;
  font-size: 22px !important;
  font-weight: 700 !important;
  margin: 0 0 4px !important;
  text-align: left !important;
}

.raceDate {
  color: #0387e9;
  font-size: 13px;
}

.raceName {
  color: #2b364c;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .4px;
}

.data {
  padding: 0rem 0.75rem;
}

.event-date {
  margin: 0 0 0 13px !important;
}
</style>