import EventListService from "../api/services/EventListService"

const eventList = {
  state: {
    events: []
  },
  getters: {
    getEvents (state) {
      return state.events
    }
  },
  mutations: {
    setEvents (state, data) {
      state.events = []
      state.events = data
    }
  },
  actions: {
    fetchAllEvents ({ commit }) {
      return EventListService.getAllEvents().then(
        data => {
          commit('setEvents', data.data.data)
          return Promise.resolve(data.data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    fetchEventsByYear ({ commit }, { year }) {
      return EventListService.getEventsByYear(year).then(
        data => {
          commit('setEvents', data.data.data)
          return Promise.resolve(data.data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    
  }
}

export default eventList