import { createStore } from 'vuex';
import eventList from '@/modules/event/event.list.store';
import race from '@/modules/race/race.store';
import auth from '@/modules/authentication/auth.store';
import virtualRace from '@/modules/virtualRace/virtualRace.store'
import upload from '@/modules/upload/upload.store'

const store = createStore({
  modules: {
    eventList,
    race,
    auth,
    virtualRace,
    upload
  }
});

export default store;