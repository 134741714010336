<template>

  <i class="top-icon" @click="scrollToTop()">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-up" viewBox="0 -3 10 16">
      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
    </svg>
  </i>

</template>

<script>

  export default {
    name: 'ScrollTop',
    methods: {
      scrollToTop: function() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }

</script>
<style>

  .top-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #2b364c5c;
    color: white;
    height: 45px;
    width: 45px;
    border-radius: 45px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .top-icon:hover {
    background-color: #2b364c;
    transform: scale(1.2);
  }

</style>
