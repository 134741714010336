<template>

  <MainLoadingScreen v-if="!isReady" />
  <div v-else>
    <div class="menu-row">
      <router-link to="/eventList">Vissza az eseményekhez</router-link>
    </div>
    
    <div v-if="getTable && getMeta" class="title">
      {{ getRaceName }}
    </div>

    <search-form v-if="getTable" />
    <div v-if="!getTable && getMeta" class="errormsg">
      A {{ getRaceName }} versenynek üres az eredménylistája!
    </div>
    <div v-if="!getTable && !getMeta" class="errormsg">
      A versenynek nincs egyelőre eredménylistája!
    </div>

    <result-table v-if="isReady && getTable" />
    <scroll-top />
  </div>

</template>

<script>

  import { mapGetters, mapActions } from 'vuex'
  import SearchForm from '@/modules/race/components/SearchForm'
  import ResultTable from '@/modules/race/components/ResultTable'
  import ScrollTop from '@/modules/race/components/ScrollTop'
  import MainLoadingScreen from '@/modules/loading/pages/MainLoadingScreen.vue'

  export default {
    name: 'RaceResult',
    data() {
      return {
        isReady: false,
        fetchRaceReady: false,
        sendFormReady: false,

      }
    },
    components: {
      SearchForm,
      ResultTable,
      ScrollTop,
      MainLoadingScreen
    },
    computed: {
      ...mapGetters(['getTable', 'getRaceName', 'getMeta'])
    },
    methods: {
      ...mapActions(['fetchRaceInfo', 'sendForm'])
    },
    mounted() {
      this.fetchRaceInfo({ raceId: this.$route.params.raceId }).then(
        value => {
          this.fetchRaceReady = true
          return Promise.resolve(value)
        }
      ).catch(
        error => {
          return Promise.reject(error)
        }
      )

      this.sendForm({ raceId: this.$route.params.raceId }).then(
        value => {
          this.sendFormReady = true
          return Promise.resolve(value)
        }
      ).catch(
        error => {
          return Promise.reject(error)
        }
      )
    },
    watch: {
      fetchRaceReady(newValue) {
        if (this.sendFormReady && newValue) {
          this.isReady = true
        }
      },
      sendFormReady(newValue) {
        if (this.fetchRaceReady && newValue) {
          this.isReady = true
        }
      }
    }
  }

</script>
<style scoped>

  .title {
    background-image: linear-gradient(270deg, #0adde5, #0387e9);
    z-index: 1;
    color: #2b364c;
    font-size: 1.4em;
    padding: 10px;
    font-weight: 700;
  }

  .row {
    margin: 0 !important;
  }

  .menu-row {
    background-color: #2b364c;
    color: #fff;
    padding: 10px;
    text-align: left;
  }

  .menu-row a {
    background-color: #2b364c;
    margin-left: 20px;
    color: #fff;
    text-decoration: none;
  }

  .menu-row a:visited,
  .menu-row a:hover,
  .menu-row a:active {
    text-decoration: none;
    color: #fff;
  }

  .errormsg {
    text-align: center;
    background-image: linear-gradient(270deg, #0adde5, #0387e9);
    z-index: 1;
    color: rgb(255, 0, 0);
    font-size: 1.3em;
    padding: 30px 10px 20px 10px;
    font-weight: 650;
  }

</style>
