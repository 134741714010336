<template>

    <nav>
      <div class='showPage'>{{pagingMeta.from}}-{{pagingMeta.to}} / {{pagingMeta.total}}</div>
      <ul class="pagination pagination-sm justify-content-center">
        <li class="page-item" @click="paging(1)">
          <span class="page-link" aria-label="First">
            <span aria-hidden="true">&laquo;</span>
          </span>
        </li>
        <li class="page-item" @click="paging(actualPage-1)" v-if="actualPage-1 > 0">
          <span class="page-link" aria-label="Previous">
            <span aria-hidden="true">&lt;</span>
          </span>
        </li>
        <li class="page-item" v-for="index in pageArray" :key="index" @click="paging(index)"
          :class="{ active: isActualPage(index) }"><span class="page-link">{{index}}</span></li>
        <li class="page-item" @click="paging(actualPage+1)" v-if="actualPage+1 <= maxPage">
          <span class="page-link" aria-label="Next">
            <span aria-hidden="true">&gt;</span>
          </span>
        </li>
        <li class="page-item" @click="paging(maxPage)">
          <span class="page-link" aria-label="Last">
            <span aria-hidden="true">&raquo;</span>
          </span>
        </li>
      </ul>
    </nav>
  
  </template>
  
  <script>
  
  export default {
    name: 'TablePaging',
    props: ['pagingMeta'],
    emits: ['paging'],
    computed: {
      maxPage () {
        return this.pagingMeta.last_page
      },
      actualPage () {
        return this.pagingMeta.current_page
      },
      pageArray () {
        const pageArray = []
        for (let i = this.actualPage - 3; i < this.actualPage + 4; i++) {
          if (i > 0 && i <= this.maxPage) {
            pageArray.push(i)
          }
        }
        return pageArray
      }
    },
    methods: {
      paging (pageNumber) {
        if (pageNumber > 0 && pageNumber <= this.maxPage && pageNumber !== this.actualPage) {
          this.$emit('paging', pageNumber)
        }
      },
      isActualPage (index) {
        return this.actualPage === index
      }
    }
  }
  </script>
  
  <style scoped>
  .page-link {
    background-color: #015794 !important;
    color: #fff !important;
    cursor: pointer;
  }
  
  .page-link:hover {
    background-color: #0372c4 !important;
  }
  
  nav {
    background-color: #015794;
    padding: 5px;
    margin-top: 1px;
    margin-bottom: 1px;
    text-align: center;
    color: white;
  }
  </style>
  