<template>
  <div class="loading-image">
    <img src="@/assets/fac_logo.png" class="img-fluid" />
  </div>
</template>

<script>

export default {
  name: 'LoadingLogo'
}
</script>

<style scoped>
.loading-image{
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width:150px;
}
</style>
