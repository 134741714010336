import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSignInAlt,
  faSignOutAlt,
  faHome,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

library.add(faSignInAlt, faSignOutAlt, faHome, faUser, faUserPlus);

export { FontAwesomeIcon };
