import VirtualRaceResultService from "../api/services/VirtualRaceResultService";

const list = {
    state: {
      results: [],
      headers: [],
    },
    getters: {
      getResults (state) {
        return state.results
      },
      getVirtualHeaders (state) {
        return state.headers
      }
    },
    mutations: {
      setResults (state, data) {
        state.results = []
        state.results = data
      },
      setVirtualHeaders (state, data) {
        state.headers = []
        state.headers = data
      }
    },
    actions: {
      async fetchResults ({ commit }, { page, searchBib, searchName }) {
        try {
          let pageLink = 'pageNumber=50'
          if (page) {
            pageLink = `${pageLink}&page=${page}`
          }
          //  if page is set we just add the search parameters at the end of the link
          if (searchBib && searchName && Number.isInteger(searchBib) && searchBib > 0) {
            pageLink = `${pageLink}&bib=${searchBib}&name=${searchName}`
          } else if (searchBib && Number.isInteger(searchBib) && searchBib > 0) {
            pageLink = `${pageLink}&bib=${searchBib}`
          } else if (searchName) {
            pageLink = `${pageLink}&name=${searchName}`
          }
          const results =
            await VirtualRaceResultService.getVirtualRace(pageLink)
              .then(data => commit('setResults', data))
          return Promise.resolve(results)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async fetchHeader ({ commit }) {
        try {
          const results =
            await VirtualRaceResultService.getVirtualRaceHeader()
              .then(data => commit('setVirtualHeaders', data))
          return Promise.resolve(results)
        } catch (error) {
          return Promise.reject(error)
        }
      }
    }
  }
  
  export default list
  