<template>

  <tr class="hoverCursor" v-for="(result, index) in results" :key="index">
    <td v-for="(value, name, index) in result" :key="index">
      <span v-if="name=='nem' && value =='F'">♂</span>
      <span v-if="name=='nem' && value =='N'">♀</span>
      <span v-if="name!='nem'">{{value}}</span>
    </td>
  </tr>

</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'ResultTableItem',
    props: ['results'],
    computed: {
      ...mapGetters(['getMeta']),
    },
  }

</script>
<style scoped>
  path {
    stroke: #1877F2;
  }
</style>
