import axios from 'axios';
import authHeader from './auth.header';

const API_URL = process.env.VUE_APP_VIRTUAL_RACE_API_BASE_URL;

class VirtualRaceUploadService {
    async uploadExcel (form) {
        let token = authHeader()['Authorization'];
        
        let result = await axios.post(API_URL + '/admin/resultlists/bp150_2023/excel', form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token
            }
        });

        return result;
    }
}

export default new VirtualRaceUploadService();