import VirtualRaceUploadService from "../api/services/VirtualRaceUploadService"

const list = {
    state: {
      responseMessage: ""
    },
    getters: {
        getResponseMessage (state) {
        return state.responseMessage
      }
    },
    mutations: {
        setResponseMessage (state, data) {
        state.responseMessage = data
      }
    },
    actions: {
      async upload ({ commit }, { form }) {
        try {
          const results =
            await VirtualRaceUploadService.uploadExcel(form)
              .then(data => commit('setResponseMessage', data))
          return Promise.resolve(results)
        } catch (error) {
          return Promise.reject(error)
        }
      }
    }
  }
  
  export default list
  