import { createRouter, createWebHistory } from "vue-router";
import eventListRoutes from '@/modules/event/routes'
import raceResultRoutes from '@/modules/race/routes'
import authRoutes from '@/modules/authentication/routes'
import virtualRaceResultRoutes from '@/modules/virtualRace/routes'
import uploadVirtualRaceRoutes from '@/modules/upload/routes'

const routes = [
  {
    path: '/',
    redirect: { path: '/eventList' }
  },
  ...eventListRoutes,
  ...raceResultRoutes,
  ...authRoutes,
  ...virtualRaceResultRoutes,
  ...uploadVirtualRaceRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
