import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL;

class EventListService {
    getEventsByYear(year) {
        return axios.get(API_URL + `/eventListt/${year}`);
    }

    getAllEvents() {
        return axios.get(API_URL + '/eventList');
    }
}

export default new EventListService();