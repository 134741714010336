<template>

  <div class="main-loading">
    <loading-rectangle />
  </div>

</template>

<script>

import LoadingRectangle from '@/modules/loading/components/LoadingRectangle'

export default {
  name: 'PageLoading',
  components: {
    LoadingRectangle
  }
}

</script>

<style scoped>
.main-loading {
  background-color: #015794;
  position: relative;
  height:100vh;
}
</style>
