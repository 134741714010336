import axios from 'axios';
import authHeader from './auth.header';

const API_URL = process.env.VUE_APP_VIRTUAL_RACE_API_BASE_URL + '/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.authorization.token) {
          let user = {
            id: response.data.user.id,
            username: response.data.user.name,
            email: response.data.user.email,
            token: response.data.authorization.token
          }
          localStorage.setItem('user', JSON.stringify(user));
          return user;
        }
      });
  }

  logout() {
    return axios
      .get(API_URL + 'logout', { headers: authHeader() })
      .then(response => {
        if (response.data.message === "Logged out") {
          localStorage.removeItem('user');
          return response.data.message;
        }
      })
      .catch(error => {
        localStorage.removeItem('user');
        return error.message;
      })
  }

  register(user) {
    return axios.
      post(API_URL + 'signup', {
        name: user.name,
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.authorization.token) {
          let user = {
            id: response.data.user.id,
            username: response.data.user.name,
            email: response.data.user.email,
            token: response.data.authorization.token
          }
          localStorage.setItem('user', JSON.stringify(user));
          
          return user;
        } else {
          return response.data;
        }

      })
  }
}

export default new AuthService();
