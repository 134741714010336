<template>

<table-paging :pagingMeta="getPagingMeta" @paging="paging" />
<div class="table-scroll-frame">
	<table class="table table-hover">
		<thead class="thead-dark">
			<tr>
				<th v-if="getMeta.cert" scope="col">Oklevél<br />Certificate</th>
				<th v-if="getMeta.facebookShare" scope="col">Megosztás<br />Share</th>
				<th v-for="(value, name, index) in getHeaders" :key="index" scope="col">
					<span v-if="getMeta.team && value.header_hu =='Egyesület'">Csapattagok<br />Team members</span>
					<span v-else>{{value.header_hu}}<br />{{value.header_en}}</span>
				</th>
			</tr>
		</thead>
		<tbody>
			<result-table-item :results="getTableFirstHalf"/>
			<google-banner />
			<result-table-item :results="getTableSecondHalf"/>
		</tbody>
	</table>
</div>
<table-paging :pagingMeta="getPagingMeta" @paging="paging" />
</template>

<script>

	import { mapGetters, mapActions } from 'vuex'
	import ScrollBooster from 'scrollbooster'
	import ResultTableItem from '@/modules/race/components/ResultTableItem'
	import TablePaging from '@/modules/paging/components/TablePaging'
	import GoogleBanner from '@/modules/race/components/GoogleBanner'

	export default {
		name: 'ResultTable',
		components: {
			ResultTableItem,
			TablePaging,
			GoogleBanner
		},
		computed: {
			...mapGetters(['getTable', 'getMeta', 'getHeaders', 'getTableFirstHalf', 'getTableSecondHalf', 'getPagingMeta'])
		},
		methods: {
			...mapActions(['sendFormPage']),
			async paging (page) {
				//#TODO: implement loading screen while getting data
				await this.sendFormPage({ raceId: this.$route.params.raceId, pageNumber: page })
    		},
		},
		mounted() {
			new ScrollBooster({
				viewport: document.querySelector('.table-scroll-frame'),
				content: document.querySelector('.table-hover'),
				scrollMode: 'native',
				textSelection: true,
				direction: 'horizontal'
			})
		}
	}

</script>
<style>

.table-scroll-frame {
	overflow-x: auto;
	overflow-y: auto;
}

</style>
  