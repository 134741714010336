<template>
  <div class="datePickerContainer">
    <VueDatepicker v-model="selectedYear" year-picker close-on-scroll placeholder="Select Date" :max-date="new Date()" :min-date="new Date('2015-01-01')" locale="hu" class="datePicker" position="left" />
  </div>
  <div class="accordion" id="Events">
    <EventItem :event="event" :index="index" :key="event.id" v-for="(event, index) in getEvents"/>
  </div>
</template>




<script>

import {  mapGetters,mapActions } from 'vuex'
import EventItem from '../components/eventItem.vue'
import VueDatepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'


export default{
  components: {
    EventItem,
    VueDatepicker
  },
  data() {
    return {
      events: null,
      selectedYear: new Date().getFullYear()
    };
  },
  computed: {
    ...mapGetters(['getEvents'])
  },
  created() {
    this.fetchEventsByYear({ year: this.selectedYear })
  },
  methods: {
    ...mapActions(['fetchAllEvents', 'fetchEventsByYear']),
  },
  watch: {
    selectedYear(newSelectedYear, oldSelectedYear) {
      if (oldSelectedYear != newSelectedYear) {
        this.fetchEventsByYear({ year: newSelectedYear })
      }
    }
  },
};
</script>


<style scoped>
  .datePickerContainer {
    margin: 10px;
  }
  .datePicker {
    box-shadow: 0px 0px 6px #1976d2;
    border-radius: 5px;
    color: #1976d2;
    width: 200px;
    --dp-action-buttons-padding: 0px 10px 25px 10px;
    
  }
  .datePicker:hover {
    border-color: #1976d2;
  }
</style>