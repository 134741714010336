<template>
    <MainLoadingScreen v-if="loading" />

    <div v-else class="Container">
        <label>File
            <input type="file" id="file" v-on:change="fileUpload( $event )"/>
        </label>
        <button class="upload-button" v-on:click="submitFile()">Submit</button>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import MainLoadingScreen from '@/modules/loading/pages/MainLoadingScreen.vue'

export default ({
    name: 'UploadVirtualRace',
    components: {
        MainLoadingScreen
    },
    data () {
        return {
            file: '',
            loading: false
        }
    },
    computed: {
        ...mapGetters(['getResponseMessage']),
    },
    methods: {
        ...mapActions(['upload']),
        fileUpload(event) {
            this.file = event.target.files[0]
        },
        async submitFile() {
            this.loading = true;
            let form = new FormData();
            form.append('file', this.file);
            await this.upload({ form: form })
            .then(response => {
                this.loading = false;
                return Promise.resolve(response);
            }).catch(error => {
                this.loading = false;
                return Promise.reject(error);
            });
        }
    },
});

</script>


<style scoped>
    .Container {
        padding: 40px 10px 10px 40px;
    }

    .upload-button {
        border-radius: 5px;
        border-width: 1px;
        border-color: black;
    }
    .upload-button:hover {
        background-color: lightgray;
    }
</style>