import LoginPage from "@/modules/authentication/pages/LoginPage"
import RegisterPage from "@/modules/authentication/pages/RegisterPage"
import ProfilePage from "@/modules/authentication/pages/ProfilePage"

export default [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage
  }
]