import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL;

class RaceResultService {
    async getRaceInfo(raceId) {
        let result = await axios.get(API_URL + `/fields/${raceId}`);
        return result;
    }

    async getRaceData(url, form) {

        let result = await axios.post(API_URL + `/resultList/${url}`, form);
        return result;
    }
}

export default new RaceResultService();