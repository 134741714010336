<template>

  <tr class="outerBanner" :style="middleBannerHeight">
    <td colspan="20">
      <div class="innerBanner">
        <div :id="id"></div>
      </div>
    </td>
  </tr>

</template>

<script>

  export default {
    name: 'GoogleBanner',
    data: function() {
      return {
        size: null,
        path: null,
        id: null
      }
    },
    computed: {
      middleBannerHeight() {
        var ret = 'height:' + this.size[1] + 'px'
        return ret
      }
    },
    methods: {
      InitialValue() {
        if (document.documentElement.clientWidth > 900) {
          this.id = 'div-gpt-ad-1582915930725-0'
          this.path = '/12231987/BSI_rajtlista_oldal_kozepe_468_60_HU'
          this.size = [468, 60]
        } else {
          this.id = 'div-gpt-ad-1582915739959-0'
          this.path = '/12231987/BSI_rajtlista_oldal_kozepe_320_50_HU'
          this.size = [320, 50]
        }
      }
    },
    created() {
      this.InitialValue()
      this.$nextTick(() => {
        window.googletag = window.googletag || {cmd: []}

        window.googletag.cmd.push(() => {
          window.googletag.defineSlot(this.path, this.size, this.id).addService(window.googletag.pubads())
          window.googletag.pubads().enableSingleRequest()
          window.googletag.companionAds().setRefreshUnfilledSlots(true)
          window.googletag.enableServices()
          window.googletag.display(this.id)
        })
      })
    }
  }

</script>

<style scoped>

.innerBanner {
  text-align: center;
}

</style>
