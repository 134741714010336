<template>

  <div class="main-loading">
    <loading-logo />
    <loading-circle />
  </div>

</template>

<script>
import LoadingCircle from '@/modules/loading/components/LoadingCircle'
import LoadingLogo from '@/modules/loading/components/LoadingLogo'

export default {
  name: 'MainLoadingScreen',
  components: {
    LoadingCircle,
    LoadingLogo
  }
}

</script>

<style scoped>
.main-loading {
  background-color: #0068b3;
  height: 100vh;
  position: relative;
}
</style>
