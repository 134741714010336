<template>
    <MainLoadingScreen v-if="isMounted === false" />
    <div v-else>
      <TablePaging @paging="paging" :pagingMeta="getResults.data.meta" />
  
      <input
        class="bibInput"
        :style="`--color: ${computedBackGroundColor}`"
        type="number"
        v-model="searchBib"
        placeholder="Rajtszám"
      />
  
      <input
        type="text"
        v-model="searchName"
        placeholder="Teljes név"
      />
  
      <button @click="sendSearch()">Keresés</button>
  
      <PageLoading v-if="loading" />
  
      <ResultTable :fields="getVirtualHeaders.data" :tableData="getResults.data.data" />
    </div>
  
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import ResultTable from '@/modules/virtualRace/components/ResultTable.vue'
  import TablePaging from '@/modules/paging/components/TablePaging.vue'
  import MainLoadingScreen from '@/modules/loading/pages/MainLoadingScreen.vue'
  import PageLoading from '@/modules/loading/pages/PageLoading.vue'
  
  export default {
    name: 'TableView',
    components: {
      ResultTable,
      TablePaging,
      MainLoadingScreen,
      PageLoading
    },
    data () {
      return {
        isMounted: false,
        loading: false,
        searchBib: null,
        searchName: null,
        bibBackGroundColor: 'white'
      }
    },
    async mounted () {      
      await this.fetchResults({ page: null, searchBib: null, searchName: null })
      await this.fetchHeader()
  
      this.isMounted = true
    },
    computed: {
      ...mapGetters(['getResults', 'getVirtualHeaders']),
      computedBackGroundColor: function () {
        return this.bibBackGroundColor
      }
    },
    methods: {
      ...mapActions(['fetchResults', 'fetchHeader']),
      paging (page) {
        this.loading = true
        this.fetchResults({ page: page, searchBib: this.searchBib, searchName: this.searchName })
      },
      sendSearch () {
        this.loading = true
        if ((this.searchBib && (!Number.isInteger(this.searchBib) || this.searchBib < 0)) || this.searchBib === 0) {
          this.bibBackGroundColor = 'red'
        } else {
          this.bibBackGroundColor = 'white'
        }
        this.fetchResults({ page: null, searchBib: this.searchBib, searchName: this.searchName })
      }
    },
    watch: {
      getResults () {
        this.loading = false
      }
    }
  }
  </script>
  
  <style scoped>
    input {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: inline-block;
    width: 350px;
    margin: 10px 20px 20px 20px;
    padding: 10px 45px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  
    button {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      display: inline-block;
      width: 120px;
      height: 40px;
      background: white url("../../../assets/search-svgrepo-com.svg") no-repeat 10px center;
      background-size: 20px 20px;
      margin: 20px;
      padding: 0px 0px 0px 30px;
      font-size: 20px;
      border: none;
      border-radius: 5px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  
    .bibInput {
      background-color: var(--color);
    }
  
  </style>
  