import RaceResultService from '../api/services/RaceResultService'

const race = {
  state: () => ({
    form: {},
    table: [],
    headers: {},
    meta: {},
    pagingMeta: {},
    pagingLinks: {},
    errorMessage: ""
  }),
  getters: {
    getForm: state => state.form,
    getTable: state => state.table,
    getHeaders: state => state.headers,
    getMeta: state => state.meta,
    getPagingMeta: state => state.pagingMeta,
    getPagingLinks: state => state.pagingLinks,
    getRaceName: state => state.meta.raceTitle,
    getTableFirstHalf: state => state.table.slice(0,5),
    getTableSecondHalf: state => state.table.slice(5)
  },
  mutations : {
    setForm (state, form) {
      state.form = {}
      state.form = form
    },
    setTable (state, table) {
      state.table = []
      state.table = table
    },
    setRaceInfo (state, info) {
      state.meta = {}
      state.headers = {}
      if (info != "Ez az eredménylista üres") {
        state.meta = info.meta
        state.headers = info.data
      } else {
        state.meta = undefined
        state.headers = undefined
      }
    },
    setResult (state, result) {
      state.pagingMeta = {}
      state.pagingLinks = {}
      state.table = {}
      if (result != "Ez az eredménylista üres") {
        state.pagingMeta = result.meta
        state.pagingLinks = result.links
        state.table = result.data
      } else {
        state.pagingMeta = undefined
        state.pagingLinks = undefined
        state.table = undefined
      }
    }
  },
  actions:{
    async fetchRaceInfo({ commit }, { raceId }) {
      await RaceResultService.getRaceInfo(raceId).then(
        data => {
          commit('setRaceInfo', data.data)
          return Promise.resolve(data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    async sendForm({ commit, dispatch }, {form, raceId}) {
      if (form) {
        commit('setForm', form)
      } else {
        commit('setForm', {}) //undefined form
      }
      await dispatch('send', {raceId: raceId})
    },
    async sendFormPage({  dispatch }, { raceId, pageNumber}) {
        const pageNumberLink = `?page=${pageNumber}`
        await dispatch('send', {raceId: raceId, pageNumber: pageNumberLink})
    },
    async send({ commit, state }, {raceId, pageNumber}) {
      const url = pageNumber ? `${raceId}${pageNumber}` : `${raceId}`
      
      await RaceResultService.getRaceData(url, state.form).then(
        result => {
          commit('setResult', result.data)
          return Promise.resolve(result);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
  }
}

export default race
