<template>
    <table v-if="converted" id="tableComponent" class="table table-bordered table-striped">
      <thead>
        <tr>
          <th v-for="field in tableColumns" :key="field" >
            {{ field.header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tableRows" :key="item">
          <td v-for="cell in item.cells" :key="cell"> {{ cell }} </td>
        </tr>
      </tbody>
    </table>
  </template>
  
  
  <script>
  import 'bootstrap/dist/css/bootstrap.min.css'
  
  export default {
    props: {
      fields: {
        type: Array
      },
      tableData: {
        type: Array
      }
    },
    data() {
      return {
        tableColumns: undefined,
        tableRows: undefined,
        converted: false,
      }
    },
    methods: {
      convertDataToCorrectFormat() {
        let columns = this.fields;
        let rows = this.tableData;

        rows.forEach(row => {
          for (let i = 0; i < row.cells.length; i++) {
            let currentColumn = columns[i];
            if (currentColumn['cell_type'] === "number" || currentColumn['cell_type'] === "bib") {
              row.cells[i] = parseFloat(row.cells[i]).toFixed(currentColumn['cell_res'])
            }
          }
        });

        this.tableColumns = columns
        this.tableRows = rows
        this.converted = true
      }
    },
    watch: {
      tableData() {
        this.convertDataToCorrectFormat()
      }
    },
    created() {
      this.convertDataToCorrectFormat()
    }
  }
  </script>
  